/* COLORS */

/* open-sans-regular - cyrillic_latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('../fonts/opensans/open-sans-v15-cyrillic_latin-regular.woff2') format('woff2'),  url('../fonts/opensans/open-sans-v15-cyrillic_latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - cyrillic_latin */

@font-face {
  font-family: 'Open Sans SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url('../fonts/opensans/open-sans-v15-cyrillic_latin-600.woff2') format('woff2'),  url('../fonts/opensans/open-sans-v15-cyrillic_latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

/* GLOBAL STYLES */

a,
div,
h1,
h2,
p {
  font-family: Arial, Geneva, Helvetica, sans-serif;
}

a,
a:visited,
a:focus {
  text-decoration: none;
}

.prevent-scroll {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.link {
  color: #d39163;
}

.link__img {
  vertical-align: middle;
}

/* GLOBAL STYLES */

/* BUTTON STYLES */

.button {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid black;
  padding: 22px 40px;
  transition-property: background, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  background: #fff;
}

.button:hover {
  color: white;
  background-color: black;
  cursor: pointer;
}

.button--black-theme {
  color: white;
  border-color: white;
  background: #000;
}

.button--black-theme:hover {
  color: black;
  background-color: white;
}

/* BUTTON STYLES */

/* MODAL WINDOW */

.modal {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal__wrapper {
  position: relative;
  background: #ffffff;
}

.modal__text-wrapper {
  margin: 65px 100px 65px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
}

.modal__header {
  font-size: 30px;
  text-align: center;
}

.modal__sub-header {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  margin-top: 15px;
  text-align: center;
  max-width: 350px;
}

.modal__line {
  display: block;
  width: 30%;
  height: 1px;
  background: black;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 30px;
}

.modal__text-input {
  font-size: 16px;
  margin-top: 40px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid #9A9A9A;
  outline: none;
  transition: border 0.5s 'ease-in-out', color 0.5s 'ease-in-out';
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

.modal__text-input:focus::-webkit-input-placeholder {
  color: transparent;
}

.modal__text-input:focus:-moz-placeholder {
  color: transparent;
}

.modal__text-input:focus::-moz-placeholder {
  color: transparent;
}

.modal__text-input:focus:-ms-input-placeholder {
  color: transparent;
}

.modal__text-input.not-valid {
  border-bottom-color: #FF0000;
  color: #FF0000;
}

.modal__button {
  padding: inherit 0;
  text-align: center;
  display: block;
  margin-top: 40px;
  align-self: stretch;
}

.modal__close {
  width: 35px;
  height: 35px;
  position: absolute;
  right: -10%;
  top: -10%;
}

.modal__close:hover {
  cursor: pointer;
}

.modal__success {
  display: none;
}

.modal__failed {
  display: none;
}

/* CLOSE */

.close {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.close::before {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: rotate(-45deg);
}

.close::after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: rotate(45deg);
}

.close:hover {
  opacity: 1;
  cursor: pointer;
}

/* CLOSE */

/* MODAL WINDOW */

/* MAIN NAV */

.main-nav {
  height: 70%;
  margin-left: 9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-nav__item {
  transition: opacity 0.4s;
  color: #ffffff;
  text-transform: uppercase;
}

.main-nav__link {
  transition: opacity 0.4s;
  font-size: 20px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
}

.main-nav__link:hover {
  opacity: 1;
}

.main-nav__dresses {
  opacity: 0.5;
  padding-top: 50px;
}

/* MAIN NAV */

/* MOBILE MENU */

.mobile-menu {
  display: none;
  z-index: 200;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 46px;
  background: black;
}

.mobile-menu__wrapper {
  margin: 0 15px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.mobile-menu__text {
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 12px;
}

.mobile-menu__hamburger {
  margin-top: 12px;
  max-width: 40px;
}

.mobile-menu-full {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 150;
  background: #000000;
  padding-top: 46px;
  transform: translateY(-100%);
  transition: transform 0.5s 'ease-in-out';
}

.mobile-menu-full__nav {
  margin-left: 0;
  margin-top: 10%;
  text-align: right;
  margin-right: 15px;
}

.mobile-menu-full__dresses {
  padding-top: 0;
  position: relative;
}

.mobile-menu-full__dresses::before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 2px;
  background-color: white;
  opacity: 0.4;
  right: 90px;
  top: 6px;
}

.mobile-menu-full__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5% 20% 0 20%;
}

.mobile-menu-full--open {
  transform: translateY(0%);
}

/* MOBILE MENU */

/* fixed menu */

.menu-left {
  z-index: 200;
  position: absolute;
  left: 0;
  top: 0;
  width: 8.5%;
}

.menu-left__fixed {
  border-right: 1px solid #202020;
  background: black;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 8.5%;
}

.menu-left__icon {
  margin-bottom: 45px;
}

.menu-left__text {
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: #ffffff;
  margin-top: 45px;
  text-decoration: none;
  text-transform: uppercase;
}

.menu-left__language {
  font-size: 16px;
  color: #ffffff;
}

.hamburger {
  position: relative;
  width: 40px;
  height: 18px;
}

.hamburger__line {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  left: 0;
  transition-property: transform, left, top;
  transition-duration: 0.5s;
  transition-timing-function: 'ease-in-out';
  background: #ffffff;
}

.hamburger__line--1 {
  top: 0;
}

.hamburger__line--2 {
  top: 50%;
}

.hamburger__line--3 {
  top: 100%;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger--open .hamburger__line--1 {
  top: 50%;
  transform: rotate(-135deg);
}

.hamburger--open .hamburger__line--2 {
  left: -200px;
}

.hamburger--open .hamburger__line--3 {
  top: 50%;
  transform: rotate(135deg);
}

.hamburger--mobile-open .hamburger__line--1 {
  top: 50%;
  transform: rotate(-135deg);
}

.hamburger--mobile-open .hamburger__line--2 {
  transform: rotate(135deg);
}

.hamburger--mobile-open .hamburger__line--3 {
  top: 50%;
  transform: rotate(135deg);
}

/* fixed menu */

/* open menu */

.open-menu {
  width: 50%;
  height: 100vh;
  position: fixed;
  z-index: 150;
  margin-left: 8.5%;
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transform: translate(-120%);
  transition: transform 0.5s 'ease-in-out';
}

.open-menu--open {
  transform: translate(-1%);
}

/* open menu */

/* global slider container */

.universal__container {
  margin-left: 8.5%;
  height: 100%;
  overflow-x: hidden;
}

.anim-not-ready {
  opacity: 0;
}

/* progress-bar settings */

.slider-progress-bar {
  transition: width 1s ease-in-out;
  position: fixed;
  z-index: 100;
  left: 8.5%;
  bottom: 0;
  background-color: #D39163;
  height: 8px;
  width: 22.875%;
}

/* fix the problem with slick fullheight */

#main-slider .slick-slide {
  height: 100vh;
  width: 17%;
  content: 'viewport-units-buggyfill; height: 100vh;';
}

/* fix the problem with slick fullheight */

/* VOLIFERT SLIDE 1 */

.volifert {
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  position: relative;
}

.volifert__text--mobile {
  display: none;
  z-index: 10;
  width: 100%;
  height: 100%;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 70px;
}

.volifert__text-side {
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  margin-bottom: 30pt;
}

.volifert__text-desc {
  position: absolute;
  color: #fff;
  font-size: 30pt;
  text-transform: uppercase;
  top: 90px;
  font-size: 18pt;
  right: 3%;
}

.volifert__text-subdesc {
  position: absolute;
  color: #fff;
  font-size: 16pt;
  text-transform: uppercase;
  top: 27%;
  right: 1%;
}

.volifert__text-subdesc h3 {
  margin-bottom: 15px;
}

.volifert__text-subdesc h3 + h3 {
  padding-left: 30px;
}

.volifert__text-subdesc h3 + h3 + h3 {
  padding-left: 60px;
}

.volifert__main-header {
  font-size: 110pt;
  text-align: center;
  font-weight: bold;
}

.volifert__main-header--mobile {
  font-size: 30px;
  position: relative;
  text-align: center;
}

.volifert__main-header--mobile::before {
  display: block;
  content: "";
  width: 40px;
  height: 1px;
  background-color: white;
  position: absolute;
  left: 45%;
  top: -35px;
}

.volifert__mobile-text {
  margin-top: 15px;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  max-width: 80%;
}

.volifert__sub-header {
  opacity: 1;
  text-align: center;
  font-size: 35pt;
}

.volifert__text {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-top: 25px;
  max-width: 80%;
}

.volifert__pic-side {
  flex-basis: 100%;
  height: 100vh;
  background-image: url("../img/glassdress.jpg");
  background-size: cover;
  background-position: 100% 50%;
}

.text-desc {
  text-align: right;
}

.text-desc__title {
  margin-bottom: 20px;
}

.text-desc__desc {
  font-size: 20pt;
  font-weight: bold;
}

/* VOLIFERT SLIDE 1 */

/* slide-secondary SLIDE 2-4 */

.slide-secondary {
  display: flex;
  height: 100vh;
  align-items: center;
}

.slide-secondary__link {
  color: black;
}

.slide-secondary__mobile-pic {
  display: none;
  width: 112%;
  margin: 30px -6% 30px -6%;
}

.slide-secondary__slider-side {
  flex-grow: 1;
  flex-basis: 60%;
  max-width: 60%;
  height: 100%;
}

.slide-secondary__text-side {
  flex-basis: 40%;
  flex-grow: 1;
  justify-content: center;
}

.slide-secondary__text-container {
  max-width: 60%;
  margin-left: 15%;
}

.slide-secondary__sub-header {
  font-size: 18px;
  color: #9A9A9A;
}

.slide-secondary__main-header {
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
  margin-top: 35px;
  position: relative;
  /*&::before {
      display: block;
      content: "";
      width: 250px;
      height: 1px;
      background-color: #000;
      position: absolute;
      left: -285px;
      top: 50%;
    }*/
}

.slide-secondary__text {
  font-size: 18px;
  line-height: 21px;
  color: #505050;
  margin-top: 35px;
  text-align: left;
}

.slide-secondary__text--mobile {
  display: none;
  margin: 0 5%;
}

.slide-secondary__text--desktop {
  display: block;
}

.slide-secondary__options {
  margin-top: 40px;
  text-align: left;
}

.slide-secondary__option {
  font-size: 18px;
  line-height: 21px;
  color: #505050;
  margin-top: 15px;
}

.slide-secondary__button {
  margin-top: 50px;
  white-space: nowrap;
}

.slide-secondary__button--mobile {
  text-align: center;
  display: none;
  margin: 40px 0 60px 0;
}

.slide-secondary--black-theme {
  background-color: black;
}

.slide-secondary--black-theme .slide-secondary__text-side {
  color: white;
}

.slide-secondary--black-theme .slide-secondary__link {
  color: white;
}

.slide-secondary--black-theme .slide-secondary__option,
.slide-secondary--black-theme .slide-secondary__sub-header,
.slide-secondary--black-theme .slide-secondary__text {
  color: #AAAAAA;
}

#main-no-scroll .slide-secondary__main-header::before {
  display: none;
}

/* slide-secondary SLIDE 2-4 */

/* SLIDERS inside secondary slides */

.top-slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.top-slider__big-img {
  height: 100%;
  background-size: cover;
  background-position: 50% 0;
  transition: background 0.8s;
  width: 85%;
  margin-left: 15%;
}

.top-slider__big-img--1 {
  background-image: url("../img/award-main.jpg");
}

.top-slider__big-img--2 {
  background-image: url("../img/bride-main.jpg");
}

.top-slider__big-img--3 {
  background-image: url("../img/coctail-main.jpg");
}

.top-slider__big-img--4 {
  background-image: url("../img/accessories-main.jpg");
}

.top-slider__big-img--awarddress {
  background-image: url(../img/awarddress/awarddress-page-slider-1.jpg);
  background-position: center !important;
}

.top-slider__thumbnails {
  height: 185px;
  display: flex;
}

.top-slider__thumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  filter: brightness(0.4);
  transition: 0.3s;
}

.top-slider__thumbnail:hover {
  filter: brightness(1);
}

.top-slider__btn {
  border-radius: 50%;
  right: 45px;
  outline: none;
  font-size: 0;
  background: #fff;
  width: 45px;
  height: 45px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  line-height: 20px;
  text-align: center;
  z-index: 1;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.top-slider__btn:before {
  font-family: FontAwesome;
  color: #5c5c5c;
  position: relative;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.top-slider__btn--fullscreen {
  bottom: 45px;
}

.top-slider__btn--fullscreen:before {
  content: "\f065";
  font-size: 24px;
  top: 11px;
}

.top-slider__btn--video {
  display: none;
  bottom: 105px;
}

.top-slider__btn--video:before {
  content: "\f04b";
  font-size: 14px;
  top: 12px;
  left: 2px;
}

.top-slider__btn--video:not([data-video='']) {
  display: block;
}

.top-slider__btn--mobile {
  top: 15px;
  right: 15px;
}

.top-slider__btn:hover {
  background: #d39163;
}

.top-slider__btn:hover:before {
  color: #fff;
}

.thumbnail-slider__wrapper {
  overflow: hidden;
  box-sizing: border-box;
  width: 15%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.thumbnail-slider__item {
  height: 100%;
}

.thumbnail-slider__item--table {
  display: inline-table !important;
}

.thumbnail-slider__more {
  position: relative;
  z-index: 1;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  padding-top: 48%;
  padding-bottom: 48%;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition-property: background,color;
  transition-property: background,color;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.thumbnail-slider.slick-vertical {
  height: 100% !important;
}

.thumbnail-slider.slick-vertical .slick-list,
.thumbnail-slider.slick-vertical .slick-track {
  height: 100% !important;
}

.thumbnail-slider.slick-vertical .thumbnail-slider__item,
.thumbnail-slider.slick-vertical .thumbnail-slider__image {
  height: 100%;
}

.thumbnail-slider.slick-vertical .thumbnail-slider__image {
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-position: 50% 50% !important;
  transition: filter 0.3s 'ease-in-out';
  filter: brightness(50%);
}

.thumbnail-slider.slick-vertical .thumbnail-slider__image:hover {
  cursor: pointer;
  filter: none;
}

.thumbnail-slider.slick-vertical .slick-slide {
  height: 16.6% !important;
  font-size: 0;
  border: none;
}

.thumbnail-slider.slick-vertical .slick-slide > div {
  height: 100%;
}

.thumbnail-slider.slick-vertical .slick-slide.slick-selected .thumbnail-slider__image {
  filter: none;
}

/* HOVER EFFECT */

.thumbnail-slider .slick-slide img {
  transition: filter 0.3s 'ease-in-out';
  filter: brightness(50%);
}

.thumbnail-slider .slick-slide.slick-selected img {
  filter: none;
}

.thumbnail-slider .slick-slide:hover img {
  cursor: pointer;
  filter: none;
}

/* HOVER EFFECT */

/* SLIDERS inside secondary slides */

.animated {
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
}

.fancy img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  #main-slider .slick-slide {
    width: auto;
    font-size: 0;
    height: auto;
  }

  .slide-secondary__text-side {
    margin-left: 3%;
  }

  .slide-secondary__text-container {
    max-width: 80%;
    margin-left: 3%;
  }

  .slide-secondary__main-header {
    font-size: 35px;
    margin-top: 15px;
  }

  .slide-secondary__text {
    margin-top: 15px;
    font-size: 16px;
    line-height: 18px;
  }

  .slide-secondary__options {
    margin-top: 20px;
  }

  .slide-secondary__option {
    font-size: 16px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1100px) {
  .menu-left__text {
    font-size: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .menu-left__text {
    transform: rotate(-90deg);
    font-size: 14px;
  }

  .hamburger {
    width: 50%;
  }

  .volifert__main-header {
    font-size: 75pt;
  }

  .slide-secondary {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  .slide-secondary__slider-side {
    max-width: 100%;
    width: 100%;
    height: auto;
    flex-basis: 0;
  }

  .slide-secondary__text-side {
    order: 0;
  }

  .slide-secondary__text-container {
    margin-left: 5%;
    max-width: 85%;
    text-align: center;
  }

  .slide-secondary__main-header {
    font-size: 28px;
    margin-top: 20px;
    text-align: center;
  }

  .slide-secondary__text {
    margin-top: 14px;
    font-size: 16px;
    margin-top: 10px;
  }

  .slide-secondary__options {
    margin-top: 20px;
  }

  .slide-secondary__button {
    margin: 30px 0;
  }

  .top-slider {
    height: auto;
  }

  .top-slider__btn {
    display: none;
  }

  .top-slider__btn--video:not([data-video='']) {
    display: none;
  }

  #main-slider .top-slider__big-img {
    display: none;
  }

  .thumbnail-slider__wrapper {
    width: 100%;
    height: auto;
    position: relative;
  }

  .thumbnail-slider__item--table {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 33.3%;
  }

  .thumbnail-slider__image {
    overflow: hidden;
    padding-top: 100%;
    -webkit-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    background-position: 50% 50% !important;
    transition: filter 0.3s 'ease-in-out';
    filter: brightness(50%);
  }

  .thumbnail-slider__image:hover {
    cursor: pointer;
    filter: none;
  }

  .thumbnail-slider.slick-vertical .thumbnail-slider__image {
    filter: none;
  }

  .thumbnail-slider .slick-slide img {
    filter: none;
  }
}

@media screen and (max-width: 1024px) and screen and (max-width: 1024px) {
  .thumbnail-slider__image {
    filter: none;
  }
}

@media screen and (max-width: 568px) {
  .modal__wrapper {
    width: 100%;
    height: 100%;
  }

  .modal__text-wrapper {
    margin: 40px 35px 40px 35px;
  }

  .modal__header {
    font-size: 18px;
  }

  .modal__close {
    top: 10px;
    right: 10px;
  }

  .close::before {
    background-color: #C9C9C9;
  }

  .close::after {
    background-color: #C9C9C9;
  }

  .mobile-menu {
    display: block;
  }

  .mobile-menu-full {
    display: block;
  }

  .menu-left {
    display: none;
  }

  .open-menu {
    display: none;
  }

  .universal__container {
    margin-left: 0;
  }

  .slider-progress-bar {
    display: none;
  }

  #main-slider .slick-slide {
    height: auto;
  }

  .volifert {
    flex-direction: column;
    margin-top: 46px;
    position: relative;
  }

  .volifert__text--mobile {
    display: flex;
  }

  .volifert__text-side {
    display: none;
  }

  .volifert__text-desc {
    display: none;
  }

  .volifert__text-subdesc {
    display: none;
  }

  .volifert__main-header {
    font-size: 25px;
  }

  .volifert__pic-side {
    width: 100%;
    height: 90vh;
    background-position: 100% 0;
    filter: brightness(60%);
  }

  .slide-secondary {
    height: auto;
    position: relative;
  }

  .slide-secondary__mobile-pic {
    display: block;
    position: relative;
    margin-left: 0;
    width: 100%;
  }

  .slide-secondary__text-side {
    margin-left: 0;
  }

  .slide-secondary__text-container {
    max-width: 100%;
    margin: 0;
  }

  .slide-secondary__sub-header {
    text-align: center;
  }

  .slide-secondary__main-header {
    font-size: 30px;
    margin-top: 0;
    text-align: center;
  }

  .slide-secondary__text--mobile {
    display: block;
  }

  .slide-secondary__text--desktop {
    display: none;
  }

  .slide-secondary__options--mobile {
    display: none;
  }

  .slide-secondary__button {
    display: block;
    text-align: center;
    padding: 22px 0;
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
  }

  .slide-secondary__button--desktop {
    display: none;
  }

  .slide-secondary__button--mobile {
    display: block;
  }

  .top-slider {
    position: absolute;
    height: auto;
    top: 49%;
    z-index: 1;
  }

  .top-slider__btn--fullscreen {
    display: none;
  }

  .top-slider__btn--video:not([data-video='']) {
    display: none;
  }

  .top-slider__btn--video {
    display: none;
  }

  #top-slider-3 {
    top: 47%;
  }

  #top-slider-4 {
    top: 38.5%;
  }

  .thumbnail-slider__wrapper {
    height: auto;
  }

  .thumbnail-slider__item {
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .thumbnail-slider__item img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .thumbnail-slider__item--table {
    display: none !important;
  }

  .thumbnail-slider__image {
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .animated {
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
  }
}